<template>
  <div>
    <Header />
    <div class="about-page">
      <div class="overlay">
        <!-- Seção de Introdução -->
        <section class="introduction">
          <h1>Sobre Nós</h1>
          <h3>
            Bem-vindo ao nosso complexo de quadras de areia, onde paixão e excelência se encontram para proporcionar a melhor experiência esportiva. Fundado em 2024, nosso objetivo é oferecer instalações de primeira linha para atletas de todos os níveis.
          </h3>
        </section>

        <!-- Carrossel de Imagens -->
        <section class="carousel-section">
  <div class="carousel-border">
    <Swiper
      :modules="[Autoplay, Navigation, Pagination]"
      :slides-per-view="1"
      :loop="true"
      :autoplay="{ delay: 3000, disableOnInteraction: false }"
      navigation
      :pagination="{ clickable: true }"
      class="mySwiper"
    >
      <SwiperSlide v-for="(image, index) in carouselImages" :key="index">
        <div class="carousel-slide">
          <img :src="image" alt="Imagem do Carrossel" />
          <div class="carousel-caption">
            <h3>Quadra {{ index + 1 }}</h3>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  </div>
</section>

        <!-- Seção de Depoimentos -->
        <section class="testimonials">
          <h2>O que Nossos Clientes Dizem</h2>
          <div class="testimonial-list">
            <div class="testimonial" v-for="testimonial in testimonials" :key="testimonial.id">
              <p>"{{ testimonial.mensagem }}"</p>
              <h4>- {{ testimonial.nome }}</h4>
            </div>
          </div>
        </section>

        <!-- Seção de Política de Reembolso -->
        <section id="refund-policy" class="refund-policy">
          <h2>Política de Reembolso</h2>
          <div class="policy-content">
            <h3>Cancelamentos e Reembolsos</h3>
            <p id="p1">
              Entendemos que imprevistos acontecem. Oferecemos cancelamento gratuito até 24 horas antes da data da reserva ou até 7 dias após a compra, o que ocorrer primeiro. Reembolsos serão processados para reservas canceladas dentro desse período.
            </p>
            <h3>Como Solicitar um Reembolso</h3>
            <p>
              Para solicitar um reembolso, entre em contato conosco através do e-mail:
              <strong>arenasunsetlavras@gmail.com</strong>. Por favor, inclua seu número de reserva e o motivo do cancelamento.
            </p>
          </div>
        </section>

      </div>
    </div>
    <Footer />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// Importando os módulos diretamente de 'swiper'
import { Autoplay, Navigation, Pagination } from "swiper/modules";

interface TeamMember {
  id: number;
  nome: string;
  cargo: string;
  foto: string;
}

interface Testimonial {
  id: number;
  nome: string;
  mensagem: string;
}

export default defineComponent({
  name: "AboutPage",
  components: {
    Header,
    Footer,
    Swiper,
    SwiperSlide,
  },
  setup() {
    const carouselImages = ref<string[]>([
      require("@/assets/quadra1.jpeg"), 
      require("@/assets/quadra2.jpeg"),
      require("@/assets/quadra3.jpeg"),
      require("@/assets/quadra4.jpeg"),
      require("@/assets/quadra5.jpeg"),
    ]);

    const team = ref<TeamMember[]>([
      {
        id: 1,
        nome: "João Silva",
        cargo: "Diretor Geral",
        foto: require('@/assets/quadras.jpg'),
      },
      {
        id: 2,
        nome: "Maria Oliveira",
        cargo: "Treinadora Chefe",
        foto: require('@/assets/quadras.jpg'),
      },
      {
        id: 3,
        nome: "Carlos Santos",
        cargo: "Gerente de Operações",
        foto: require('@/assets/quadras.jpg'),
      },
    ]);

    const testimonials = ref<Testimonial[]>([
      {
        id: 1,
        nome: "Ana Souza",
        mensagem: "Adoro treinar aqui! As instalações são excelentes e a equipe é muito atenciosa.",
      },
      {
        id: 2,
        nome: "Pedro Lima",
        mensagem: "Um ambiente incrível para praticar beach tennis. Recomendo para todos os níveis!",
      },
      {
        id: 3,
        nome: "Luiza Martins",
        mensagem: "A organização dos eventos é impecável. Sempre me divirto muito!",
      },
    ]);

    return {
      carouselImages,
      team,
      testimonials,
      Autoplay,
      Navigation,
      Pagination,
    };
  },
});
</script>

<style scoped>
/* CSS global */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Classe .about-page corrigida */
.about-page {
  position: relative;
  width: 100%;
  min-height: 100vh;
  
  /* Imagem de fundo */
  background-image: url('@/assets/quadras.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  
  display: flex;
  flex-direction: column;
}

/* Overlay para o conteúdo */
.overlay {
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding: 40px 20px;
  
  /* Fundo semi-transparente para melhorar a legibilidade */
  background: rgba(0, 0, 0, 0.6);
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Alinha para o topo */
}

/* Seção de Introdução */
.introduction {
  max-width: 920px;
  text-align: center;
  color: white;
}

.introduction h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.introduction h3 {
  font-size: 1.2em;
  line-height: 1.6;
}

/* Carrossel de Imagens */
.carousel-section {
  width: 80%; /* Não ocupa a largura inteira */
  max-width: 1000px; /* Ajuste para maior visibilidade */
  margin: 50px auto; /* Centralizado com espaçamento */
  border-radius: 15px; /* Bordas arredondadas */
  overflow: hidden; /* Para garantir que as bordas arredondadas sejam aplicadas */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adicionando sombra para destaque */
}

.carousel-border {
  padding: 10px; /* Espessura da borda */
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%); /* Gradiente da borda */
  border-radius: 20px; /* Bordas arredondadas para a borda externa */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra para dar destaque */
}

.mySwiper {
  border-radius: 15px; /* Bordas arredondadas para o carrossel interno */
  overflow: hidden; /* Garante que o conteúdo fique dentro da borda */
  width: 100%;
  height: 400px;
}

.carousel-slide {
  position: relative;
  width: 100%;
  height: 100%;
}

.carousel-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel-caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 20px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
  color: white;
}

.carousel-caption h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.carousel-caption p {
  font-size: 1em;
}

/* Seção de Depoimentos */
.testimonials {
  width: 100%;
  max-width: 1000px;
  margin-bottom: 50px;
}

.testimonials h2 {
  color: white;
  font-size: 2em;
  text-align: center;
  margin-bottom: 20px;
}

.testimonial-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.testimonial {
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
  padding: 20px;
  border-radius: 15px;
  width: 100%;
  max-width: 1000px;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
}

.testimonial:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
}

.testimonial p {
  font-size: 1.1em;
  color: white;
  margin-bottom: 10px;
}

.testimonial h4 {
  font-size: 1em;
  color: white;
}

/* Seção de Política de Reembolso */
.refund-policy {
  width: 100%;
  max-width: 1000px;
  margin-bottom: 30px;
}

.refund-policy h2 {
  color: white;
  font-size: 2em;
  text-align: center;
  margin-bottom: 20px;
}

.policy-content {
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
  padding: 20px;
  border-radius: 15px;
  color: white;
}

.policy-content h3 {
  font-size: 1.5em;
  color: white;
}

.policy-content p {
  font-size: 1em;
  line-height: 1.6;
}

#p1 {
  margin-bottom: 15px;
}

/* Swiper Customização */
.swiper-pagination-bullet {
  background: white;
  opacity: 0.7;
}

.swiper-pagination-bullet-active {
  background: #FF6A00;
}

.swiper-button-prev,
.swiper-button-next {
  color: white;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  color: #FF6A00;
}

/* Responsividade */
@media (max-width: 768px) {
  .carousel-section {
    width: 90%; /* Ajuste para dispositivos móveis */
    height: 250px;
    max-width: 100%; /* Remove a restrição de largura máxima */
    margin: 30px auto; /* Reduz o espaçamento */
  }

  .mySwiper {
    height: 250px; /* Reduzir altura no mobile */
  }

  .carousel-caption h3 {
    font-size: 1.2em;
  }

  .carousel-caption p {
    font-size: 0.9em;
  }

  .testimonials h2,
  .refund-policy h2 {
    font-size: 1.8em;
  }

  .introduction h1 {
    font-size: 2em;
  }

  .introduction p {
    font-size: 1em;
  }

  .policy-content h3 {
    font-size: 1.2em;
  }

  .policy-content p {
    font-size: 0.9em;
  }

  /* Ajustes na imagem de fundo para mobile */
  .about-page {
    background-size: cover; /* Mantém cover para garantir cobertura */
    background-attachment: scroll; /* Remove fixed para melhorar o desempenho em mobile */
    background-position: center top; /* Ajusta o posicionamento da imagem */
  }

  .overlay {
    padding: 20px 10px; /* Reduz o padding em mobile */
  }

  .carousel-section {
    border-radius: 10px; /* Menos arredondado */
  }

  .carousel-border {
    border-radius: 15px; /* Menos arredondado */
  }

  .testimonial {
    width: 90%;
  }

  .refund-policy {
    margin-bottom: 20px;
  }
}

@media (max-width: 480px) {
  .introduction h1 {
    font-size: 1.8em;
  }

  .introduction p {
    font-size: 1em;
  }

  .policy-content h3 {
    font-size: 1.2em;
  }

  .policy-content p {
    font-size: 0.9em;
  }

  .carousel-caption h3 {
    font-size: 1em;
  }

  .carousel-caption p {
    font-size: 0.8em;
  }
}

@media (max-width: 768px) {

.about-page {
  background: none; /* Remove o fundo padrão em telas menores */
}

.about-page::before {
content: ''; /* Pseudo-elemento para o fundo fixo */
position: fixed; /* Faz com que a imagem permaneça fixa no viewport */
top: 0;
left: 0;
width: 100%;
height: 100%;

/* Imagem de fundo */
background-image: url('@/assets/quadras.jpg'); /* Substitua pelo caminho correto */
background-size: cover; /* Faz a imagem cobrir toda a área */
background-position: center; /* Centraliza a imagem */
background-repeat: no-repeat;

z-index: -1; /* Coloca a imagem atrás de todo o conteúdo */
}

.overlay {
  padding: 20px 10px; /* Reduz o espaçamento no mobile */
}

.overlay{
  padding: 20px;
}

}

@media (max-width: 480px) {
.about-page::before {
  position: fixed; /* Mantém o fundo fixo no mobile */
  background-size: cover; /* Faz a imagem cobrir o viewport */
  background-position: center; /* Centraliza a imagem no mobile */
}
}

</style>
