<template>
    <div class="container">
      <Header/>
      <HomeBanner/>
      <WelcomeBanner/>
      <PlanosBanner/>
      <QuadrasBanner/>
      <Footer/>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent } from 'vue';
  import Header from '../components/Header.vue';
  import HomeBanner from '../components/home/HomeBanner.vue';
  import WelcomeBanner from '../components/home/WelcomeBanner.vue';
  import Footer from '../components/Footer.vue';
  import PlanosBanner from '../components/home/PlanosBanner.vue'
  import QuadrasBanner from '../components/home/QuadrasBanner.vue'
  import ModernAnnouncementBanner from '@/components/home/ModernAnnouncementBanner.vue';
  
  export default defineComponent({
    name: 'Home',
    components: {
      Header,
      HomeBanner,
      WelcomeBanner,
      Footer,
      PlanosBanner,
      QuadrasBanner,
    }
  });
  </script>
  
  <style scoped>

  </style>
  