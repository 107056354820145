<template>
  <div class="chatbot-container">
    <transition
      @before-enter="beforeEnter"
      @enter="enter"
      @before-leave="beforeLeave"
      @leave="leave"
    >
      <div v-if="isOpen" class="chatbot-content">
        <div class="chatbot-header" @click="toggleChat">
          Ajuda <span class="toggle-icon">{{ isOpen ? "⬇️" : "⬆️" }}</span>
        </div>
        <div class="chatbot-messages">
          <transition-group name="slide-up" tag="div">
            <div
              v-for="(message, index) in messages"
              :key="index"
              :class="['message', message.sender]"
            >
              {{ message.text }}
            </div>
          </transition-group>
        </div>
        <div class="chatbot-input">
          <input
            v-model="userInput"
            placeholder="Digite sua mensagem..."
            @keyup.enter="sendMessage"
          />
          <button @click="sendMessage">Enviar</button>
        </div>
      </div>
    </transition>

    <div v-show="showChatIcon" class="chat-icon" @click="toggleChat">
      <font-awesome-icon icon="comment-dots" style="font-size: 1.5em; margin-left: 1px;" />

    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import axios from "axios";

export default {
  name: "ChatBot",
  setup() {
    const isOpen = ref(false);
    const showChatIcon = ref(true);
    const userInput = ref("");
    const messages = ref([
      {
        text: "Olá! Como posso te ajudar? Digite 1, 2, 3 ou 4 para ver as opções:\n1 - Informações sobre preços\n2 - Horários de funcionamento\n3 - Serviços\n4 - Reembolsos e Cancelamentos",
        sender: "bot",
      },
    ]);

    const currentStep = ref("mainMenu");
    const userEmailMessage = ref("");

    const toggleChat = () => {
      if (!isOpen.value) {
        // Abrindo o chatbot
        isOpen.value = true;
        showChatIcon.value = false;
      } else {
        // Fechando o chatbot
        isOpen.value = false;
        // Atrasar a aparição do ícone da bola azul em 1 segundo
        setTimeout(() => {
          showChatIcon.value = true;
        }, 1000);
      }
    };

    const sendMessage = () => {
      const input = userInput.value.trim();
      if (input === "") return;

      messages.value.push({ text: input, sender: "user" });
      processMessage(input);
      userInput.value = "";
    };

    const resetToInitialMessage = () => {
      setTimeout(() => {
        messages.value.push({
          text: "Olá! Como posso te ajudar? Digite 1, 2, 3 ou 4 para ver as opções:\n1 - Informações sobre preços\n2 - Horários de funcionamento\n3 - Serviços\n4 - Reembolsos e Cancelamentos",
          sender: "bot",
        });
        currentStep.value = "mainMenu";
      }, 1000);
    };

    const processMessage = (message) => {
      let botReply = "";
      let followUp = "";

      if (currentStep.value === "mainMenu") {
        if (message === "1") {
          botReply =
            "Você escolheu 1 - Informações sobre preços. Deseja saber sobre:\n1.1 - Plano mensal\n1.2 - Plano trimestral\n1.3 - Sem plano (avulso)";
          currentStep.value = "prices";
        } else if (message === "2") {
          botReply =
            "Você escolheu 2 - Horários de funcionamento. Nossos horários são:\nSegunda a Sexta - 8h às 18h\nSábado - 9h às 14h";
          followUp = "Esse horário resolve sua dúvida? Digite 'sim' ou 'não'.";
          currentStep.value = "confirm";
        } else if (message === "3") {
          botReply =
            "Você escolheu 3 - Serviços. Escolha uma opção para saber mais:\n3.1 - Aulas\n3.2 - Agendamento de Quadras";
          currentStep.value = "services";
        } else if (message === "4") {
          botReply =
            "Cancelamentos e Reembolsos:\nOferecemos cancelamento gratuito até 24 horas antes da data da reserva ou até 7 dias após a compra, o que ocorrer primeiro.\nPara solicitar um reembolso, entre em contato através do e-mail: arenasunsetlavras@gmail.com. Inclua seu número de reserva e o motivo do cancelamento.";
          followUp = "Isso resolve sua dúvida? Digite 'sim' ou 'não'.";
          currentStep.value = "confirm";
        } else {
          botReply = "Número inválido. Por favor, escolha 1, 2, 3 ou 4.";
        }
      } else if (currentStep.value === "prices") {
        if (message === "1.1") {
          botReply =
            "Plano Mensal:\n- R$200,00 antes de 17:00\n- R$300,00 a partir de 17:00";
          followUp = "Isso resolve sua dúvida? Digite 'sim' ou 'não'.";
          currentStep.value = "confirm";
        } else if (message === "1.2") {
          botReply = "Plano Trimestral:\n- R$600,00 para todos os horários.";
          followUp = "Isso resolve sua dúvida? Digite 'sim' ou 'não'.";
          currentStep.value = "confirm";
        } else if (message === "1.3") {
          botReply =
            "Sem Plano (Avulso):\n- R$50,00 antes de 17:00\n- R$80,00 a partir de 17:00\nCom plano mensal: R$75,00 a partir de 17:00\nCom plano trimestral: R$65,00 a partir de 17:00";
          followUp = "Isso resolve sua dúvida? Digite 'sim' ou 'não'.";
          currentStep.value = "confirm";
        } else {
          botReply = "Número inválido. Por favor, escolha 1.1, 1.2 ou 1.3.";
        }
      } else if (currentStep.value === "services") {
        if (message === "3.1") {
          botReply =
            "Oferecemos aulas de Beach Tennis e Futevôlei. Consulte a seção de Agendamento de Aulas no site para mais informações.";
          followUp = "Isso resolve sua dúvida? Digite 'sim' ou 'não'.";
          currentStep.value = "confirm";
        } else if (message === "3.2") {
          botReply =
            "Oferecemos aluguel de quadras para diversas modalidades. Verifique a disponibilidade e faça sua reserva online.";
          followUp = "Isso resolve sua dúvida? Digite 'sim' ou 'não'.";
          currentStep.value = "confirm";
        } else {
          botReply = "Número inválido. Por favor, escolha 3.1 ou 3.2.";
        }
      } else if (currentStep.value === "confirm") {
        if (message.toLowerCase() === "sim") {
          botReply =
            "Fico feliz em ajudar! Se precisar de algo mais, digite 'iniciar' para ver as opções novamente.";
          resetToInitialMessage();
        } else if (message.toLowerCase() === "não") {
          botReply =
            "Deseja:\n1 - Voltar para as opções iniciais\n2 - Enviar um e-mail sobre seu problema";
          currentStep.value = "afterNo";
        } else {
          botReply = "Por favor, responda com 'sim' ou 'não'.";
        }
      } else if (currentStep.value === "afterNo") {
        if (message === "1") {
          resetToInitialMessage();
        } else if (message === "2") {
          botReply =
            "Por favor, digite a mensagem que gostaria de enviar para nossa equipe.";
          currentStep.value = "emailInput";
        } else {
          botReply = "Opção inválida. Por favor, escolha 1 ou 2.";
        }
      } else if (currentStep.value === "emailInput") {
        userEmailMessage.value = message;
        sendEmail(userEmailMessage.value);
        botReply = "Enviando sua mensagem...";
      }

      messages.value.push({ text: botReply, sender: "bot" });

      if (followUp) {
        setTimeout(() => {
          messages.value.push({ text: followUp, sender: "bot" });
        }, 500);
      }
    };

    const sendEmail = async (message) => {
      try {
        await axios.post(`${process.env.VUE_APP_BACKEND_URL}/send-email`, {
          message,
        });
        messages.value.push({
          text: "Sua mensagem foi enviada com sucesso. Nossa equipe entrará em contato em breve.",
          sender: "bot",
        });
        resetToInitialMessage();
      } catch (error) {
        messages.value.push({
          text: "Ocorreu um erro ao enviar sua mensagem. Por favor, tente novamente mais tarde.",
          sender: "bot",
        });
      }
    };

    // Transições suaves para animação de altura
    const beforeEnter = (el) => {
      el.style.height = "0";
      el.style.opacity = "0";
    };

    const enter = (el, done) => {
      el.style.transition = "height 0.4s ease, opacity 0.4s ease";
      el.style.height = el.scrollHeight + "px";
      el.style.opacity = "1";
      setTimeout(() => {
        el.style.height = "auto";
        el.style.transition = "";
        done();
      }, 400);
    };

    const beforeLeave = (el) => {
      el.style.height = el.scrollHeight + "px";
      el.style.opacity = "1";
    };

    const leave = (el, done) => {
      el.style.transition = "height 0.4s ease, opacity 0.4s ease";
      el.style.height = "0";
      el.style.opacity = "0";
      setTimeout(() => {
        done();
      }, 400);
    };

    return {
      isOpen,
      showChatIcon,
      userInput,
      messages,
      toggleChat,
      sendMessage,
      beforeEnter,
      enter,
      beforeLeave,
      leave,
    };
  },
};
</script>
<style scoped>
.chatbot-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
}

.chat-icon {
  width: 60px;
  height: 60px;
  background-color: #007bff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding-left: 1px; /* Espaçamento entre o ícone e o conteúdo do chatbot */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.chat-icon:hover {
  transform: scale(1.1);
}

.chatbot-content {
  width: 300px;
  max-width: 80vw; /* Ajuste para telas menores */
  overflow: hidden;
  background-color: #007bff;
  color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.chatbot-header {
  background-color: #0056b3;
  padding: 15px;
  font-weight: bold;
  text-align: center;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.toggle-icon {
  margin-left: 8px;
  color: #ccc;
  transition: transform 0.3s ease;
}

.chatbot-messages {
  max-height: 300px;
  overflow-y: auto;
  padding: 15px;
  background-color: #333;
}

.message {
  margin: 8px 0;
  padding: 8px;
  border-radius: 5px;
  white-space: pre-wrap;
  opacity: 0;
  animation: fadeIn 0.5s ease forwards;
}

.message.bot {
  background-color: #555;
  text-align: left;
  animation-delay: 0.1s;
}

.message.user {
  background-color: #007bff;
  color: white;
  text-align: right;
  animation-delay: 0.2s;
}

.chatbot-input {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ccc;
}

.chatbot-input input {
  flex: 1;
  padding: 8px;
  border: none;
  border-radius: 5px;
  outline: none;
}

.chatbot-input button {
  margin-left: 8px;
  padding: 8px 12px;
  background-color: #555;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.chatbot-input button:hover {
  background-color: #333;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.5s ease;
}

.slide-up-enter,
.slide-up-leave-to {
  transform: translateY(10px);
  opacity: 0;
}

/* Responsividade para telas menores */
@media (max-width: 600px) {
  .chatbot-container {
    bottom: 10px;
    right: 10px;
    left: 10px;
    flex-direction: column-reverse;
    align-items: flex-end;
  }

  .chatbot-content {
    width: 100%;
    max-width: 100%;
    border-radius: 10px 10px 0 0;
    margin-left: 0;
  }

  .chatbot-messages {
    max-height: calc(100vh - 200px);
  }

  .chatbot-header {
    font-size: 18px;
  }

  .chatbot-input button {
    padding: 8px 10px;
  }
}
</style>

  